import React from 'react';
import styled from 'styled-components';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const GalleryContainer = styled.div`
  & .image-container {
    margin: 0 20px 0 0;
  }
  & img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }

  & .slideshow-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: transparent;

    color: #323232;
    font-size: 26px;
    margin-top: 0;
    padding: 10px;
    height: 100%;
    font-family: 'Garnett Light', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    &.next {
      right: 0;
    }

    &.prev {
      left: 0;
    }
  }
`;

export const FabricGallery = ({ images }) => {
  const fabricImages = images.map((node, index) => (
    <div key={`single_gallery_image_${index}`} className="image-container">
      <img src={node.url} alt={node.altText} />
    </div>
  ));

  return (
    <GalleryContainer>
      <Slide
        indicators={true}
        autoplay={true}
        duration={2000}
        arrows={true}
        slidesToShow={3}
        prevArrow={<button className="slideshow-arrow prev">←</button>}
        nextArrow={<button className="slideshow-arrow next">→</button>}
      >
        {fabricImages}
      </Slide>
    </GalleryContainer>
  );
};
